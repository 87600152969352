import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";

import ReadingsHero from "../sections/readings/Readings.Hero";
import Section from "@components/Section";
import SEO from "@components/SEO";
// import Layout from "@components/Layout";
import { LayoutBase } from "@components/Layout";
import Paginator from "@components/Navigation/Navigation.Paginator";

import ReaddingsList from "../sections/readings/Readings.List";

import { Template } from "@types";

const seoImage = '/trongnguyen.co-seo-reading.jpg';

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            hero {
              readingHeading
            }
            name
          }
        }
      }
    }
  }
`;

const ReadingsPage: Template = ({ location, pageContext }) => {
  const reading = pageContext.group;
  const authors = pageContext.additionalContext.authors;

  const results = useStaticQuery(siteQuery);
  const site = results.allSite.edges[0].node.siteMetadata;

  return (
    <LayoutBase>
      <SEO
        pathname={location.pathname}
        title={site.hero.readingHeading + " - " +  site.name}
        image={seoImage}
      />
      <ReadingsHero authors={authors} />
      <Section narrow>
        <ReaddingsList articles={reading} />
        <ArticlesPaginator show={pageContext.pageCount > 1}>
          <Paginator {...pageContext} />
        </ArticlesPaginator>
      </Section>
    </LayoutBase>
  );
};

export default ReadingsPage;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && `margin-top: 64px;`}
`;
